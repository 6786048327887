import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { Headline, StyledHeadlineWrapper } from "../components/headline"
import Img from "gatsby-image"
import SEO from "../components/seo"
import { PageSwiper } from "../components/pageSlider"
import styled from "styled-components"
import { Contact } from "../components/contact"
import { MoreLink } from "../components/moreLink"
import { AllWordpressWpProjekte } from "../hooks/allWordpressWpProjekte"

const StyledFeatureItemPrimary = styled.article`
    position: relative;
`

const StyledFeatureItemSecondary = styled.article`
    margin-top: 150px;
    margin-bottom: 50px;
    position: relative;
`

const StyledIntroText = styled.q`
  font-style: italic;
  font-family: 'Merriweather', Georgia, serif;
`

const StyledFeatureImgBoxShadow = styled.div`
    position: relative;
    margin-top: 30px;
      ::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 75%;
      right: -30px;
      top: -30px;
      filter: url("#${props => props.backgroundColor}");
      background: white;    
      z-index: -1;
    }
`

const StyledSecondImageRow = styled.div`
  ::before {
  content: '';
    width: 41.66667%;
    padding-bottom: 33%;
    margin-top: -100%;
  }
`


export default ({ data }) => {

  const allProjects = AllWordpressWpProjekte()

  let counter = 0
  const FeatureList = () => {
    return (
      allProjects.allWpAcfProjekt.nodes.map((item, index) => {
          const featuredItem = data.wpAcfFotograf.acfFotografenDetails.featuredProjects.find(element => element.projekt.slug === item.slug)
          featuredItem && counter++

          if (counter === 1) {
            return (
              featuredItem && <StyledFeatureItemPrimary key={index}>

                <StyledSecondImageRow className={"row align-items-end mb-3"}>
                  <div className={"col-6 offset-2 mb-4"}>
                    {item.featuredImage.node.localFile && featuredItem.previews[0] &&
                    <Img
                      className={"box-shadow"}
                      fluid={featuredItem.previews[0].localFile.childImageSharp.fluid}
                      sizes={{
                        ...featuredItem.previews[0].localFile.childImageSharp.fluid,
                        aspectRatio: 3 / 2,
                      }}/>
                    }
                    <StyledHeadlineWrapper>
                      <Headline headlineH1={item.title}/>
                    </StyledHeadlineWrapper>
                    <p>{featuredItem.text}</p>
                    <Link to={`/${item.contentType.node.name}/${item.slug}/`}>
                      <MoreLink text={"Zum Projekt"}/>
                    </Link>
                  </div>

                  <div className={"col-4"}>
                    {featuredItem.previews[1].localFile &&
                    <Img
                      className={"box-shadow"}
                      fluid={featuredItem.previews[1].localFile.childImageSharp.fluid}
                      sizes={{
                        ...featuredItem.previews[1].localFile.childImageSharp.fluid,
                        aspectRatio: 2 / 3,
                      }}/>
                    }
                  </div>
                </StyledSecondImageRow>

                <div className={"row align-items-end mb-3"}>
                  <div className={"col-7"}>
                    {featuredItem.previews[2].localFile &&
                    <Img
                      className={"box-shadow"}
                      fluid={featuredItem.previews[2].localFile.childImageSharp.fluid}
                      sizes={{
                        ...featuredItem.previews[2].localFile.childImageSharp.fluid,
                        aspectRatio: 3 / 2,
                      }}/>
                    }
                  </div>
                </div>
              </StyledFeatureItemPrimary>
            )
          }

          if (counter === 2) {
            return (
              featuredItem && <StyledFeatureItemSecondary key={index}>
                <div className={"row align-items-end mb-3"} css={{ overflow: "hidden" }}>
                  <div className={"col-6 mb-4"}>
                    <div className={"col-10 offset-2"}>
                      <StyledHeadlineWrapper>
                        <Headline headlineH1={item.title}/>
                      </StyledHeadlineWrapper>
                    </div>
                    <div className={"col-12 col-md-10"}>
                      <p>{featuredItem.text}</p>
                      <Link to={`/${item.type}/${item.slug}/`}>
                        <MoreLink text={"Zum Projekt"}/>
                      </Link>
                    </div>
                  </div>
                  <div className={"col-6 col-md-4"}>
                    {item.featuredImage.node.localFile &&
                    <StyledFeatureImgBoxShadow backgroundColor={data.wpAcfFotograf.acfFotografenDetails.farbe}>
                      {featuredItem.previews[0] &&
                      <Img
                        className={"box-shadow"}
                        fluid={featuredItem.previews[0].localFile.childImageSharp.fluid}
                        sizes={{
                          ...featuredItem.previews[0].localFile.childImageSharp.fluid,
                          aspectRatio: 2 / 3,
                        }}/>
                      }
                    </StyledFeatureImgBoxShadow>}
                  </div>
                </div>
                <div className={"row"}>
                  <div className={"col-6 offset-md-2"}>
                    {featuredItem.previews[1].localFile &&
                    <Img
                      className={"box-shadow"}
                      fluid={featuredItem.previews[1].localFile.childImageSharp.fluid}
                      sizes={{
                        ...featuredItem.previews[1].localFile.childImageSharp.fluid,
                        aspectRatio: 3 / 2,
                      }}/>
                    }
                  </div>

                  <div className={"col-6 col-md-3"}>
                    {featuredItem.previews[2].localFile &&
                    <Img
                      className={"box-shadow"}
                      fluid={featuredItem.previews[2].localFile.childImageSharp.fluid}
                      sizes={{
                        ...featuredItem.previews[2].localFile.childImageSharp.fluid,
                        aspectRatio: 3 / 2,
                      }}/>
                    }
                  </div>
                </div>
              </StyledFeatureItemSecondary>
            )
          }
          return false
        },
      )
    )
  }

  let photographers_projects = []
  allProjects.allWpAcfProjekt.nodes.map((item) => (
      item.AcfProjektDetails.projectPhotographer && item.AcfProjektDetails.projectPhotographer.map((photo) => (
          photo.slug.includes(data.wpAcfFotograf.slug) && photographers_projects.push(item)
        ),
      )
    ),
  )

  return (
    <Layout>
      <SEO title={data.wpAcfFotograf.title}/>
      <div className={"container"}>
        <div className={"row justify-content-center"}>
          <div className={"col-12"}>
            <div className={"row align-items-md-center mb-5"}>
              <div className={"col-4 col-xl-3"}>
                {data.wpAcfFotograf.featuredImage.node.localFile &&
                <Img className={"box-shadow"}
                     css={`filter: url("#${data.wpAcfFotograf.acfFotografenDetails.farbe}");`}
                     fluid={data.wpAcfFotograf.featuredImage.node.localFile.childImageSharp.fluid}/>
                }
              </div>
              <div className={"col-8 col-xl-9"}>
                <div className={"row"}>
                  <div className={"col-10 offset-2"}>
                    <StyledHeadlineWrapper>
                      Hi ich bin
                      <Headline
                        headlineH1={data.wpAcfFotograf.title}
                      />
                    </StyledHeadlineWrapper>
                  </div>

                  <div className={"col-8 col-lg-6 offset-3"}>
                    <StyledIntroText dangerouslySetInnerHTML={{ __html: data.wpAcfFotograf.acfFotografenDetails.kurzbeschreibung }}/>
                  </div>

                  <div className={"col-12 col-md-11 offset-md-1 mt-4"}>
                    <h2>{data.wpAcfFotograf.acfFotografenDetails.headline_content}</h2>
                    <p>{data.wpAcfFotograf.acfFotografenDetails.content}</p>
                  </div>
                </div>
              </div>
            </div>
            <FeatureList/>
          </div>
        </div>
      </div>
      <PageSwiper list={photographers_projects} backgroundColor={data.wpAcfFotograf.acfFotografenDetails.farbe}
                  title={data.wpAcfFotograf.title}/>
      <Contact/>
    </Layout>
  )
}

export const query = graphql`
    query ($slug: String!) {
        wpAcfFotograf(slug: {eq: $slug}) {
            date(formatString: "MMMM Do, YYYY")
            id
            title
            slug
            content
            acfFotografenDetails {
                content
                farbe
                schwerpunkte
                headlineContent
                kurzbeschreibung
                featuredProjects {
                    text
                    projekt {
                        ... on WpAcfProjekt {
                            title
                            slug
                        }
                    }
                    previews {
                        localFile {
                            childImageSharp {
                                fluid(
                                    maxWidth: 800,
                                    maxHeight: 800,
                                    quality: 90,
                                    cropFocus: CENTER
                                ) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
            featuredImage {                
                node {
                    localFile {
                        childImageSharp {
                            fluid(
                                maxWidth: 460,
                                quality: 90,
                                cropFocus: CENTER
                            ) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
    }
`
